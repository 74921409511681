@import "../../../static/styles/variables";
@import "../../../static/styles/mediaqueries";
@import "../../../static/styles/buttons";

.banner {
    height: auto;
    width: 100%;
    // padding: 30px 20px 0px;

    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;

    background-color: rgba(246, 246, 246, 0.99);
    box-shadow: 0px -2px 5px -3px rgba(0, 0, 0, 0.3);

    & > p {
        text-align: center;
        width: 90%;
        margin: auto;
        max-width: 1235px;
        line-height: 1.4;
        padding: 30px 20px 0px;

        :global {
            .blue {
                color: #0d6aad;
                white-space: nowrap;
            }
        }
    }
}

.btn_white {
    @extend .btn;
    color: $black;
    height: 40px;
    padding: 0 1.1rem;
    line-height: 1;
    border: 1px solid $very-light-pink;
    border-radius: 0;

    &:hover {
        background-color: $black;
    }

    &:last-of-type:hover {
        background-color: $primary;
    }

    @include media(m-device) {
        margin: 0 0 8px 0;
        width: 100%;
    }
}

.flex_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 0;
    width: fit-content;
    margin: auto;

    @include media(m-device) {
        flex-direction: column;
    }
}
