@import '../../../static/styles/variables';

.toggle {
    width: fit-content;
    background-color: $very-light-pink;
    position: relative;
    height: 40px;
    border-radius: 20px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: all ease-in-out .3s;

    &.reverse {
        flex-direction: row-reverse;
        background-color: $primary;
        color: $white;
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;

    & > h3 {
        font-family: $baderNarrowBold;
        font-size: 20px;
        line-height: 1;
        text-transform: none;
        padding-right: 10px;
    }
}

.switch {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: block;
    background-color: $white;
    cursor: pointer;
}

.content {
    padding: 0 10px;
    font-family: $baderNarrowBold;
    margin-top: -3px;

}

.hidden {
    visibility: hidden;
    position: absolute;
}
