.button_spinner_container {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: opacity 0.1s linear;
  transition-delay: 0.3s;
  opacity: 0;
  visibility: hidden;

  &__visible {
    opacity: 1;
    visibility: visible;
  }
}

.button_spinner {
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: button_spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}


@keyframes button_spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}