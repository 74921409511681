@import '../../../static/styles/variables';
@import '../../../static/styles/mediaqueries';
@import '../../../static/styles/buttons';


.cookie {
    &_modal {
        background-color: #f6f6f6;
        line-height: 1.4;
    }

    &_wrapper {
        padding: 20px 0 0;
    }
}

.content {
    padding: 30px 0 0;

}

.cookies {
    width: 100%;

    thead > td {
        font-family: $baderNarrowBold;

    }

    td {
        border: 1px solid $black;
        padding: 8px;
    }
}

.btn__primary {
    @extend .btn__primary;
    margin: 10px auto;
}
